.filter-container {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.filtro {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cell>.pp-tooltip {
  width: 100% !important;
}