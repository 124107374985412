.graph-container {
  color: var(--text-default);
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.date-info {
  color: var(--text-default);
  font-size: 0.7rem;
}

.bkpsilverresume .doughnut-chart>.chart {
  flex-wrap: unset;
}

.bkpsilverresume .doughnut-chart>.chart>.legend {
  min-width: unset;
}

.card-reappearing .chart-medium,
.card-unfocusing .chart-medium,
.card-hidden .chart-medium {
  max-height: 35vh;
  /* Increased from 25vh */
  min-height: 25vh;
  /* Added minimum height */
}