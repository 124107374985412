.pp-modal>.body>.content>.pp-form>.fields {
  justify-content: center;
}

.pp-modal>.body>.content  {
  /* overflow: visible; */
  /* min-height: 59vh; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.row>.cells>.cell>.divStatusColorDisplay {
  height: fit-content;
  width: fit-content;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  text-transform: uppercase;
  font-size: 12px;
}
.pp-modal > .body {
  padding: 20px;
  max-height: 98vh;
}

.pp-dropdown>.field>.value {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pp-textarea > .field  {
  /* width: 94%; */
  padding: 8px;
  /* min-height: 70px; */
}

.pp-textarea > .field > textarea.value {
  width: 95%;
}

.newRequestModal > .pp-form > .fields {
  row-gap: 10px;
}
.pp-modal>.body>.header {
  margin-bottom: 10px;
}

.newRequestModal >.pp-form > .functions {
  margin-top: 15px;
}
.newRequestModal >.pp-form > .fields > .pp-textarea > .field {
  max-height: 300px;
}

.commentDetails {
  height: fit-content;
  padding-bottom: 30px;
}

.commentDetails h4 {
  margin-bottom: 1rem;  
  color: #8B90A3;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
}
.AiMenu {
  width: 10%;
  position: relative;
  justify-content: flex-end;
  /* margin-top: -85px; */
  left: 88.5%;
  display: flex;
}

.AiMenu > .pp-dropmenu.left {
  top: 10px !important;
  right: 40px;
  /* width: 180px !important; */
}
.AiMenu > .pp-dropmenu.right {
  top: 10px !important;
  /* width: 180px !important; */
}


.AiMenu > .pp-dropmenu.left > .items, .AiMenu > .pp-dropmenu.left > .subitems, .AiMenu > .pp-dropmenu.right > .items, .AiMenu > .pp-dropmenu.right > .subitems  {
  border-radius: 10px;
  max-width: 180px !important;
}
.AiMenu > .pp-dropmenu.left > .subitems {
  margin-top: -75px !important;
}
.footerModalAnexos > button {
  width: 135px;
}
button.addComment {
  align-self: flex-end;
  width: 15%;
  text-align: center;
  padding: 1rem;
  height: 3rem;
  margin: .5rem .5rem 0rem 0rem;
  
  transition: transform .3s,

}

:has(.hidefilterPanel) .gridfilter {
  display: none !important;
}

.lcdashboard.hidefilterPanel .card-content-body {
  justify-content: flex-start;
  margin-top: 1rem;
}
.divOrderAttachmentsContent {
  display: flex;
  height: auto;
  gap: 16px;
  flex-wrap: wrap;    
}

.imgCropContainer {
  width: 280px; 
  height: 150px;    
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Máscara escura no hover */
.imgCropContainer::after {
  content: ""; 
  position: absolute;
  border-radius: 5px;    
  width: 280px;
  height: 150px;
  background: rgba(0, 0, 0, 0.3); 
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.imgCropContainer:hover::after {
  opacity: 1;
}

.imgAttachment {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  cursor: pointer;
  border-radius: 8px;
}

.divOrderAttachmentsImages {
  display: flex;
  min-height: 200px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  gap: 16px;
  flex-wrap: wrap;
}
.divIcons {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
  justify-content: center;
}
.spanCommentsTitleContent {
  display: flex;
  align-items: center;
  min-height: 18px;
  height: auto;
  margin: 8px -20px;
  left: 0px;
  width: 100%;
  font-style: normal;
  line-height: 160%;
  color: #2b304f;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #3A4F871A;

} 
.spanCommentsTitleContent:hover{
  box-shadow: 4px 4px 8px 0 rgba(20, 27, 77, .15);
}

.attachment-box {
  display: flex;
  border-radius: 5px;
  background-color: #FFFFFF;
  max-width: 180px;
  width: 180px;
  height: 54px;
  border-radius: 8px;
  padding: 8px 6px;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #3A4F871A;
  gap: 10px;
}

.attachment-icon-format{
  min-width: 20px;
}

.attachment-info-row {
  display: flex;
  width: 117px;
  align-items: center;
  height: auto;
  min-height: 37px;    
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-name {
  font-size: 14px;
  color: #2B304F; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-size {
  font-size: 12px;
  color: #666;
}

.attachment-actions {
  display: flex;
  gap: 10px;
}