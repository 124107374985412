.chat-container {
    display: flex;
    flex-direction: column;
    /* max-width: calc(60vw); */
    min-height: calc(100vh - 175px);
    min-width: calc(100%);
}

.suggestions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.hostAccountsByMonth {
    display: contents;
    width: 100% !important;
}

.hostAccount {
    width: 100% !important;
    display: contents;
}

.graphAccount {
    display: contents;
    width: 100% !important;
}
.headerLogos {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100% !important;
}

.suggestions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    grid-template-rows: repeat(2, auto);
    /* 2 rows */
    gap: 12px;
    /* Space between cards */
    width: 100%;
    margin-top: 5px;
}

.suggestion-card > div {
    padding: 10px !important;
}

.suggestion-card {
    font-size: 12px !important;
    cursor: pointer;
    margin: 0 !important;
    /* Center cards within their grid cells */
    max-width: 200px;
    color: var(--text-light) !important;
    overflow-y: auto !important;
    max-height: 120px;
    background-color: transparent !important;
    border: 1px solid var(--background-light);
    font-weight: 300;
    border-radius: 8px !important;
}

.suggestion-card:hover {
    border: 1px solid var(--color-secondary-lime);
    background-color: #fff !important;
}

.darkgrafittibutton {
    color: #2E1956 !important;
}

ol {
    padding-left: 20px !important;
}

.greybutton {
    color:var(--text-light) !important;
    background-color: transparent !important;
    
}

.greybutton:hover {
    color:var(--text-default) !important;
    background-color: transparent !important;    
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.message-list {
    max-height: calc(100vh - 279px);
    overflow-y: auto;
    padding: 16px;
    flex-grow: 1;    
}

.message-item {
    display: flex;
    padding: 8px 0;
}

.message-item.user {
    justify-content: flex-end;
}

.message-item.assistant {
    justify-content: flex-start;
}

.message-paper {
    max-width: 95%;
    min-width: 60%;
    padding: 12px;
}

.message-paper.user {
    background-color: #8C40E3;
    border: none;
    border-radius: 8px 8px 0px 8px;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);*/
}

.message-paper.user .message-content {
    color: #ffffff !important;
}

.message-content + div {
    margin-bottom: 0 !important;
}

.chat-container .MuiPaper-root {
    box-shadow: none;
}

.message-paper.assistant {
    background-color: #ffffff;
    color: var(--text-dark);
    border: 1px solid var(--background-light);
    border-radius: 8px 8px 8px 0px;
    max-width: calc(25vw - 90px);
    overflow-x: auto;
    width: 100%;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
}

.right-sidepanel.focused .message-paper.assistant {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #E2E6F3;
    border-radius: 8px 8px 8px 0px;
    max-width: calc(70vw - 90px);
    overflow-x: auto;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
}

.message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: .2rem;
    align-items: center;
}

.assistant-name {
    font-weight: 400;
    color: var(--text-light);
    font-family: 'Ubuntu' !important;
    font-size: 0.75rem !important;
}

.message-timestamp {
    color: var(--text-light);
    font-family: 'Ubuntu' !important;
    font-size: 0.75rem;
}

.message-header-right {
    display: flex;
    align-items: center;
    gap: 12px;
}

.message-content { 
    word-break: break-word;
    font-size: 13px !important;
}

.suggestions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
}

.suggestions-list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 16px;
}


.input-container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.more-prompts-button {
    text-transform: none;
    font-weight: 400;
    color: #757575;
    font-size: 16px;
    justify-content: flex-start;
    padding-left: 0px;
}

.textarea-container {
    display: flex;
    align-items: flex-end;
    margin-top: 0px;
}

.list-container {
    display: contents;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Ensure the chat container fills the available height */
    border: 0px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    justify-content: space-between;
}

.chat-messages {
    font-family: "Ubuntu", sans-serif;
    flex: 1;
    /* Allow the messages to fill the available space */
    overflow-y: auto;
    padding: 10px;
}

.message {
    font-family: "Ubuntu", sans-serif;
    background-color: #f3f3f3;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.chat-input {
    display: flex;
    padding: 10px;
}

.chat-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.chat-input .send-button {
    margin-left: 10px;
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.chat-input .send-button:hover {
    background-color: #0056b3;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.message-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
}

.message-input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
}

.text-field {
    flex-grow: 1;
    margin-right: 10px;
}

.icon-button {
    margin-right: 10px;
}

.icon-button-send {
    padding: 0.3rem 1.2rem;
    border: 0;
    border-radius: 4px;
    height: 55px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    min-width: 55px;
    FONT-WEIGHT: 500;
    font-size: large;
}

.resetThread {
    width: 20px;
    height: 20px;
    padding-left: 5px;
}

.resetThread svg {
    width: 100%;
    height: 100%;
}