.lcdashboard {
    min-height: calc(100vh - 104px);
    max-height: calc(100vh - 104px);
    display: grid;
    /*grid-template-columns: repeat(12, 1fr );*/
    grid-template-rows: 1fr;
    grid-gap: 0px
}


.overflow-hidden .lcdashboard.isHomeType {
    min-height: calc(100% + 48px);
    max-height: calc(100% + 48px);
}

.overflow-hidden .lcdashboard.isHomeType .right-sidepanel {
    grid-column-start: 1 !important;
    display: block;
    margin-left: -0.8rem !important;
}


.overflow-hidden .lcdashboard.isHomeType .right-sidepanel .cell {
    width: 100% !important;
}


.lcdashboard.isHomeType {
    min-height: calc(100vh - 133px);
    max-height: calc(100vh - 133px);
    width: 100%;
}

.lcdashboard.isSubGrid {
    min-height: 100% !important;
    max-height: 100% !important;
    display: flex;
}

.lcdashboard.isSubGrid .lcdashboard-cards .lcdashboard-cards-panel {
    display: flex;
}

.lcdashboard.isSubGrid .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body {
    display: flex;
    min-height: 100%;
    flex-wrap: wrap;
}

.lcdashboard.isSubGrid .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body .card-frame {
    width: calc(50% - 16px);
    flex: 1;
}

.lcdashboard.isSubGrid .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body {
    gap: 0.75rem;
}

.no-padding {
    padding: 0 !important;
}

.overflow-hidden {
    overflow: hidden;
}

/*.lcdashboard .chart-treemap > div {
        cursor: grab;
    }

    .lcdashboard .chart-treemap path:hover, .lcdashboard .chart-treemap text:hover {
        cursor: zoom-in !important;
    }*/





.lcdashboard .automation-resource-home {
    color: var(--color-primary-blue);
    font-weight: 400 !important;
    font-size: 14px;
}

.lcdashboard .automation-resource {
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.lcdashboard .automation-name-home {
    color: var(--text-default);
    font-size: 12px;
}

.lcdashboard .automation-name {
    color: #fff;
    font-size: 12px;
}

.lcdashboard .automation-schedule {
    color: #fff;
    font-size: 12px;
}

.lcdashboard .title-schedule {
    color: var(--text-dark);
    font-family: "Ubuntu", sans-serif;
    font-size: .9rem;
    /*font-size: 2.3vh; */
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    min-width: 60px;
}

.lcdashboard .lcdashboard-cards {
    min-height: calc(100%);
    max-height: calc(100%);
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.lcdashboard .lcdashboard-cards .lcdashboard-cards-panel {
    min-height: calc(100%);
    max-height: calc(100%);
    display: grid;
    grid-template-columns: 1fr;
    /*grid-template-rows: repeat(12, 1fr );*/
    grid-gap: 0px;
    padding: 0;
}


.lcdashboard .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-header {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: span 1;
}



.lcdashboard .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body {
    min-height: calc(100%);
    max-height: calc(100%);
    display: grid;
    /*grid-template-columns: repeat(12, 1fr );
                grid-template-rows: repeat(4, 1fr );*/
    grid-gap: 0.75rem;
}

@keyframes cardFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes cardFocused {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes cardFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes cardUnfocused {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.lcdashboard .card-frame:not(.focused):hover {
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.2);
}

.lcdashboard.isHomeType .card-frame:hover,
.lcdashboard .card-frame.small-card:hover,
.lcdashboard .card-frame.small-card {
    box-shadow: none;
}

.lcdashboard .card-frame {
    display: flex;
    background-color: #fff;
    border-radius: 0.25rem;
    padding: 0.75rem;
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
    flex-direction: column;
    justify-content: space-between;
}

.lcdashboard .card-frame.small-card {
    padding: 0.5rem 0.75rem;
}


.lcdashboard.isHomeType .card-frame.small-card {
    padding: 0.4rem !important;
}

.card-frame.no-header {
    justify-content: space-around;
}

.card-frame.fix-card-content {
    justify-content: space-between;
}

.lcdashboard.isHomeType .card-frame {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.lcdashboard .card-frame.card-focused {
    z-index: 2;
    animation: cardFocused 0.5s forwards;
}

.lcdashboard .card-frame.card-hidden {
    pointer-events: none;
    animation: cardFadeOut 0.5s forwards;
}


.card-fade {
    opacity: .3;
    animation: none !important;
}

.card-active {
    opacity: 1;
    animation: none !important;
}

.lcdashboard .card-frame.card-reappearing {
    animation: cardFadeIn 0.5s forwards;
}

.lcdashboard .card-frame.card-unfocusing {
    animation: cardUnfocused 0.5s forwards;
}

.lcdashboard .card-frame-header-content {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 0.5rem;
    align-self: stretch;
    flex-wrap: wrap;
}

.lcdashboard .card-title {
    display: flex;
    height: 1.6rem;
    min-width: 5.25rem;
    align-items: flex-end;
    flex: 1 0 0;
}

.lcdashboard .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body .card-frame .card-frame-header-content .title,
.lcdashboard .lc-title {
    color: var(--text-dark);
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    min-width: 60px;
}

.lcdashboard.isHomeType .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-body .card-frame .card-frame-header-content .title,
.lcdashboard.isHomeType .lc-title {
    font-size: .9rem;
}

.lcdashboard.isHomeType .card-title {
    font-size: .9rem;
}

.lcdashboard .lc-segment-title-row-resume {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.lcdashboard .lc-segment-title-row-resume .lc-buttons {
    justify-content: center;
    margin: 10px 0;
}


.lcdashboard .lc-segment-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
}

.lcdashboard .lc-segment-buttons .lc-buttons > .active {
    background-color: var(--color-primary-blue) !important;
    color: #fff !important;
}

/*maiores consumos*/
.lcdashboard .card-frame:has(.titlefix) .card-frame-header-content {
    display: inline;
}

.lcdashboard .card-frame:has(.titlefix) .block-icons {
    justify-content: flex-end;
}

.lcdashboard .card-frame-header-content .block-icons {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
}

.lcdashboard .card-frame .block-icons span.icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-default);
    border-radius: 50%;
    transition: 0.3s all;
    font-size: 20px;
}

.lcdashboard .card-frame span.icon i,
.lcdashboard .card-frame span.icon svg {
    padding: 5px;
    border-radius: 100%;
}

.lcdashboard .card-frame span.icon svg {
    width: 32px;
    height: 32px;
}

.lcdashboard .card-frame .block-icons span.icon>:not(.lc-iconlink):hover {
    background: var(--background-light);
    color: var(--icon-default);
    cursor: pointer;
}

.lcdashboard .card-content-body {
    max-height: calc(100% - 20px);
    min-height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
}

.lcdashboard .card-frame .centered {
    justify-content: center;
}

.lcdashboard .card-frame .fromStart {
    justify-content: flex-start;
}

.lcdashboard .card-content-body-home {
    max-height: calc(100% - 24px);
    min-height: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lcdashboard .card-content-body:has(.titlefix) {
    justify-content: flex-start;
}


.lcdashboard .card-content-body-content {
    display: contents;
}

.lcdashboard .card-content-body:has(.titlefix) {
    max-height: calc(100% - 30px);
    min-height: calc(100% - 30px);
}

.lcdashboard .lcdashboard-cards .lcdashboard-cards-panel .lcdashboard-cards-panel-footer {
    min-height: calc(100%);
    max-height: calc(100%);
    grid-column-start: 1;
    grid-column-end: 1;
    /*grid-row-start: 12;*/
    grid-row-end: span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lcdashboard .pp-dropdown .icons .ppi-x {
    display: none;
}

.lcdashboard .right-sidepanel {
    background: #fafafa;
    box-shadow: -10px 0 18px 0px rgba(58, 79, 135, 0.15);

    max-height: calc(100% + 1.5rem);
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1;
    z-index: 9;
    width: 25%;
    position: fixed;
    right: 0;
    padding: 1rem;
    margin-top: -0.75rem;
    /* margin-right: -0.2rem; */
    /* margin-left: 0.75rem; */
}

    .lcdashboard .right-sidepanel .title-sidebar {
        color: var(--text-dark);
        font-family: "Ubuntu", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
    }


.lcdashboard .right-sidepanel .right-sidepanel-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lcdashboard .right-sidepanel .title-sidebar-content {
    display: flex;
    justify-content: space-between;
}

.lcdashboard .right-sidepanel .title-icons {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

/* .lcdashboard .right-sidepanel .title-icons span, .icons-filter {
            color: #2E1956;
            transition: 0.3s all;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 0.5rem;
            /* width: 36px;
            height: 36px;
            border-radius: 50%; */
/* } */

/* .lcdashboard .right-sidepanel .title-icons span:hover, .icons-filter:hover {
                background: #cccccf;
            } */

.lcdashboard .right-sidepanel .filter-content {
    display: flex;
    flex-direction: column;
}

.lcdashboard .right-sidepanel .filter-content .right-sidepanel-filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    height: calc(100vh - 180px);
}

.lcdashboard .right-sidepanel .filter-content .right-sidepanel-filters .principal-filters {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

    .lcdashboard .right-sidepanel .advanced-filters {
        display: flex;
        flex-direction: column;
        gap: 0;
        min-height: 100px !important;
    }

.lcdashboard .right-sidepanel .advanced-filters .title {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--text-default);
    margin-bottom: 0.5rem;
}

.lcdashboard .right-sidepanel .advanced-filters .advanced-filters-content {
    /* min-height: calc(100vh - 474px); */
    /* max-height: calc(100vh - 474px); */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 35vh;
    max-height: 35vh;
}

.lcdashboard .right-sidepanel .advanced-filters .filter-condition {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.filter-condition>.MuiBox-root>.MuiGrid-container>.MuiGrid-root>.pp-tooltip>.termfield>.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    height: 27px;
    margin-top: 0px;
    width: 8vw;
}

.filter-condition>.MuiBox-root>.MuiGrid-container>.MuiGrid-root>.pp-tooltip>.termfield>.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense>input {
    padding: 4px
}

.filter-condition>.MuiBox-root>.MuiGrid-container>.MuiGrid-root>div:nth-child(2) {
    padding: 5px;
    max-height: 20vh;
    max-width: 13.5vw;
    min-width: 13.5vw;
    display: flex;

}

.filter-condition>.MuiBox-root>.MuiGrid-container>.MuiGrid-root>div:nth-child(2)>.loading {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 10vw;
    font-size: 12px;
}

.MuiGrid-root>div:nth-child(2)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    z-index: 99;
}

.MuiGrid-root>div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #cccccf;
    border-radius: 0.25rem;
}

.MuiGrid-root>div:nth-child(2)::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.MuiGrid-root>div:nth-child(2)::-webkit-scrollbar-track {
    border-radius: 0.25rem;
}

.filter-condition>.MuiBox-root>.MuiGrid-container>.MuiGrid-root>div:nth-child(2)>.sugestion {
    font-size: 10px !important;
}

.lcdashboard .cell-descricao {
    min-width: 60px;
}

.lcdashboard .big-numbers-icons {
    width: 2.2vw;
    height: 2.2vw;
}

.lcdashboard .card-big-number {
    display: flex;
    flex-direction: column;
}

.lcdashboard .card-big-number>.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.lcdashboard .card-big-number.colorfull {
    color: #fff;
}

.lcdashboard .table-card {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    color: var(--text-default);
    height: calc(100vh - 180px);
}


.lcdashboard .big-number-content {
    min-width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    /*min-height: 100%;*/
}

.lcdashboard .big-number-content {
    font-size: 2vw;
}

.lcdashboard .big-number-value {
    font-size: 2vw;
}

.lcdashboard .lc-subtitle {
    font-size: 75%;
    color: #2E1956;
    text-align: center
}


.lcdashboard .inner-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    height: 100%;
}


.page:has(.card-focused) {
    overflow-y: hidden !important;
}

.lcdashboard .chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.lcdashboard .chart-content {
    flex: 1;
    width: 100%;
    height: 100%;
}