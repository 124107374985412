.icebreakerquestionscontainer {
    border-radius: 8px;
    position: relative;
    display: flex;
    max-width: calc(35vw);
    flex-direction: column;
    color: var(--text-light);
    justify-content: center;
    font: 300 10px/12px Ubuntu, -apple-system, Roboto, Helvetica, sans-serif;
    overflow: hidden;
}

.decorativeArrow {    
    position: relative; /* Use absolute para posicionar corretamente */
    width: 16px;
    height: 16px;
    background-color: #fff;
    rotate: 45deg;
    left: 50%; /* Ajuste conforme necessário */
    bottom: 2px; /* Ajuste conforme necessário */
    transform: translateX(-50%);
}

.questionsWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: start;
    cursor: grab; /* Change cursor to indicate draggable area */
    padding: 12px 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 18px rgba(71, 31, 204, 0.15) !important;
    margin: 18px 18px 0 18px;
    
}

.icon {    
    cursor: pointer;
    margin: auto 4px;    
}
 
.questionsContainer {
    overflow-x: auto;
    display: flex;
    align-self: stretch;
    align-items: start;
    gap: 8px;
    flex: 1;
    margin: auto 0;
    scroll-behavior: smooth;
    /* Hide scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

    .questionsContainer::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }


.question {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    align-self: stretch;
    flex: 1;
    align-content: center;
    flex-wrap: wrap;
    border-radius: 8px;
    border: 1px solid var(--Fundos-02, #e2e6f3);
    min-height: 40px;
    gap: 8px;
    min-width: 140px;
    padding: 4px 8px;
    cursor: pointer;    
}


.question:hover {
    border: 1px solid var(--color-secondary-lime);
}