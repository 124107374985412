.detailsPlan {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
}

.headerPlanDetails {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 24px;
    padding: 5px 16px 0 0;
}

.detailsName {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailsName h4 {
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    color: #2B304F;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bagdeStatusPlan {
    margin-left: 5px;
    padding: 2px 8px;
    color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
}

.labelGray {
    color: #a4a9b9;
    font-size: 12px;
}

.resource_scope {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: end;
    justify-content: space-around;
    font-size: 12px;
}

.resource_scope p {
    font-weight: bold;
}

.detailSavingsPlanOrReserve {
    background-color: #F1F5FB;
    border-radius: 4px;
    padding: 12px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subTitlePlan {
    font-size: 12px;
    color: #2B304F;
}

.titleLink {
    color: #00aee1;
    text-decoration: underline;
    cursor: pointer;
}