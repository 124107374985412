[node-id] rect {
    fill: #2E1956 !important;
}

[control-expcoll-id] circle {
    fill: #e2e6f3;
}

#tree>svg {
    background-color: #fff;
}

.bg-ripple-container {
    background-color: #2E1956 !important;
}

.edit-fields>div:nth-child(2) {
    display: none !important;
}

.editForm {
    display: none;
    text-align: center;
    position: absolute;
    border: 1px solid #aeaeae;
    width: 300px;
    background-color: #2E1956;
    z-index: 10000;
    border-radius: 2rem;
    color: white;
    margin-left: 20rem;
}

.titleEditForm {
    margin-top: 0.27rem;
}

.nameField {
    width: 60%;
    font-family: "Ubuntu", sans-serif;
    height: 40px;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    background-color: #fff;
    color: var(--text-default);
    overflow: hidden;
    margin-top: 0.5rem;
}

.nameField:hover {
    border: 1px solid #898da6;
}

.footerButton {
    display: flex;
    flex-direction: row;
    margin-left: 12rem;
}
.form {
    margin-bottom: 0 !important;
}