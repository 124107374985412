.lc-datatableinfinite .pp-modal>.body>.content>form>.pp-input>.field {
    border: 1px solid #ddd;
}

.lc-datatableinfinite .pp-modal>.body>.content>form>.pp-input>.field:hover {
    border: 1px solid #898da6;
}

.lc-datatableinfinite>.topbar {
    display: flex;
    justify-content: space-between;
}

.lc-datatableinfinite>.topbar>.functions {
    color: #2E1956;
    display: flex;
    align-items: center;
    font-size: 28px;
    position: relative;
}

.lc-datatableinfinite>.topbar>.functions>div {
    display: flex;
}

.lc-datatableinfinite>.topbar>.functions>div>* {
    margin: 0 8px;
}

.lc-datatableinfinite>.topbar>.functions>div:not(:last-child) {
    padding-right: 8px;
    border-right: 1px #ddd solid;
}

.lc-datatableinfinite>.topbar>.functions>div:not(:first-child) {
    margin-left: 8px;
}

.lc-datatableinfinite>.topbar>.pagination {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

.lc-datatableinfinite>.topbar>.pagination>.pageinfo {
    border-radius: 1rem;
    padding: 2px 8px;
    margin: 0 6px;
    font-size: 0.7rem;
    background-color: #EBEBEB;
}

.lc-datatableinfinite>.header {
    position: relative;
    padding-right: .5rem;
}

.lc-datatableinfinite>.header>.row {
    display: flex;
    gap: 0;
    background-color: #fff;
    border-radius: 5px;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: #fff;
    border-bottom: solid 1px #E2E6F3;
    min-height: 28px;
}

.lc-datatableinfinite>.header>.row>.selectable {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    cursor: pointer;
}

.lc-datatableinfinite:has(.body-functions)>.header>.row>.cells {
    width: calc(100% - 28px);
}

.lc-datatableinfinite>.header>.row>.cells {
    display: flex;
    width: 100%;
    gap: 6px;
    align-items: center;
}

.lc-datatableinfinite>.header>.row>.cells>.cell {
    font-family: "Ubuntu", sans-serif;
    font-size: .75rem;
    font-weight: 700;
    padding: 3px 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    color: var(--text-default);
    min-width: 0;
}

.lc-datatableinfinite>.header>.row>.cells>.cell.sortable {
    cursor: pointer;
}

.lc-datatableinfinite>.header>.row>.cells>.cell.sortable:hover {
    background-color: #eee;
}

.lc-datatableinfinite>.header>.row>.cells>.cell.left {
    justify-content: flex-start;
    text-align: start;
}

.lc-datatableinfinite>.header>.row>.cells>.cell.center {
    justify-content: center;
    text-align: center;
}

.lc-datatableinfinite>.header>.row>.cells>.cell.right {
    justify-content: flex-end;
    text-align: end;
}

.lc-datatableinfinite>.header>.row>.functions {
    width: 28px;
}

.lc-datatableinfinite>.header>.row>.scroll {
    width: 8px;
}

.lc-datatableinfinite>.header.status {
    padding-right: 0px;
}
.lc-datatableinfinite>.header.status>.row {
    gap: 0;

}
.lc-datatableinfinite>.body {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-gutter: stable;
}

.lc-datatableinfinite>.body::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
}

.lc-datatableinfinite>.body::-webkit-scrollbar-thumb {
    background: #cccccf;
    border-radius: 0.25rem;
}

.lc-datatableinfinite>.body::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.lc-datatableinfinite>.body::-webkit-scrollbar-track {
    border-radius: 0.25rem;
}

.lc-datatableinfinite>.body>.row,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row {
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    gap: 0;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: #fff;
}

.lc-datatableinfinite>.body>.row>.selectable,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.selectable {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    cursor: pointer;
}

.lc-datatableinfinite>.body>.row>.cells,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells {
    display: flex;
    align-items: center;
    width: 100%;
    font-variant-numeric: tabular-nums;
    gap: 6px;
}

.lc-datatableinfinite>.body>.row>.cells>.cell,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell {
    font-size: .8rem;
    color: #2E1956;
    display: flex;
    padding: 0;
    min-width: 0;
    overflow: hidden;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.left,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.left {
    justify-content: flex-start;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.right,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.right {
    justify-content: flex-end;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.center,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.center {
    justify-content: center;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.between,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.between {
    justify-content: space-between;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.tiny,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.tiny {
    font-size: 0.6rem;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.small,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.small {
    font-size: 0.7rem;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.medium,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.medium {
    font-size: 0.8rem;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.large,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.large {
    font-size: 1rem;
}

.lc-datatableinfinite>.body>.row>.cells>.cell.big,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.cells>.cell.big {
    font-size: 1.2rem;
}

.lc-datatableinfinite>.body>.row>.functions,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row>.functions {
    width: 28px;
}

.lc-datatableinfinite>.body>.row:hover,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row:hover {
    background-color: #eee;
}

.lc-datatableinfinite>.body>.row:hover:not(.status),
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row:hover:not(.status) {
    border-left-color: #eee !important;
}

.lc-datatableinfinite>.body.highdensity>.row,
.lc-datatableinfinite>.body.highdensity>.lc-accordion.visible>.body>.row {
    min-height: 28px;
    margin-bottom: 1px;
}

.lc-datatableinfinite>.body.mediumdensity>.row,
.lc-datatableinfinite>.body.mediumdensity>.lc-accordion.visible>.body>.row {
    min-height: 38px;
    margin-bottom: 4px;
}

.lc-datatableinfinite>.body.lowdensity>.row,
.lc-datatableinfinite>.body.lowdensity>.lc-accordion.visible>.body>.row {
    min-height: 80px;
    margin-bottom: 4px;
}

.lc-datatableinfinite>.body>.loader {
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.lc-datatableinfinite>.body>.nodatamessage {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lc-datatableinfinite.debug>.header {
    background-color: orange;
}

.lc-datatableinfinite.debug>.header>.cells>.cell:nth-child(even) {
    background-color: blue;
}

.lc-datatableinfinite.debug>.header>.cells>.cell:nth-child(odd) {
    background-color: green;
}

.lc-datatableinfinite.debug>.header>.scroll {
    background-color: red;
}

.lc-datatableinfinite.debug>.body .row {
    background-color: orangered;
}

.lc-datatableinfinite.debug>.body .row>.cells>.cell:nth-child(even) {
    background-color: blue;
}

.lc-datatableinfinite.debug>.body .row>.cells>.cell:nth-child(odd) {
    background-color: green;
}


.lc-datatableinfinite .lc-tooltip:has(.color-status) {
    min-height: inherit;
}

.lc-datatableinfinite .color-status {
    height: 100%;
    width: 5px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    top: 0px;
    left: 0;
}


.lc-datatableinfinite:has(.color-status) .cells {
    margin-left: 10px;
}

.lc-datatableinfinite .lc-accordion.visible>.body {
    overflow: visible;
}


.lc-datatableinfinite .lc-tooltip:has(.color-status)>.body>.arrow {
    transform: translate(0px, 18px) rotate(45deg);
}

.lc-datatableinfinite .lc-tooltip:has(.color-status)>.body {
    left: 12px;
    top: -7px;
}

.lc-datatableinfinite .lc-tooltip:has(.color-status)>.body.left>.content {
    padding: 5px 16px !important;
}

.lc-datatableinfinite .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-wrap: wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow-wrap: break-word;
}

.lc-datatableinfinite>.body>.row:has(.functions)>.cells,
.lc-datatableinfinite>.body>.lc-accordion.visible>.body>.row:has(.functions)>.cells {
    width: calc(100% - 28px);
    /* flex: none; */
}

.lc-datatableinfinite .expandable-cell .expandable.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.lc-datatableinfinite .expandable-cell:has(.expanded) {
    padding: 6px 0;
}

.lc-datatableinfinite .expandable-cell .expandable.expanded {
    display: block;
}

.lc-datatableinfinite .expandable-cell .expand-button {
    background: none;
    border: none;
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding: 8px 0 0 0;
    display: inline-block;
}