.row.domainsInsights {
    opacity: 0; 
    color: #898da6;
    font-size: 11px;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    padding: 5px;
    transition: opacity .8s ease;
    position: absolute; 
    width: 14%;
}
.iconValueInsights {
    opacity: 0;
    transition: opacity .2s ease;
    padding-right: 10px;

}
.row.domainsInsights.show,
.iconValueInsights.show,
.row.subscriptionInsights.show {
    opacity: 1; 
    transition: opacity .6s ease;
    display: flex;
}

.row.subscriptionInsights {
    opacity: 0; 
    color: #898da6;
    font-size: 12px;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    align-items: flex-start;
    transition: opacity .6s ease;
    position: absolute; 
    width: auto;
    flex-direction: column;
    height: 30%;
    display: none;
}
.row.subscriptionInsights.show{
    width: 30%;
}

.row.subscriptionInsights.show.fixedAI {
    width: 23%;
}

.row.subscriptionInsights >.row {
    padding: 1px 5px;
}

p.textInfo {
    width: 100%;
    font-size: 10px;
    color: var(--text-default);
    text-align: end;
    margin-top: -20px;
}

/* .fraudDiv > .iconvaluecontent {
    min-height: 90% !important;
} */

.fraudDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
}

.item.fixedMenu {
    width: 9vw;
    font-size: 13px;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}