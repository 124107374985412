.softwareAccountsByMonth {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.softwareAccountsByMonth>.softwareAccount {
    max-height: 95%;
    width: 100%;
}

.softwareAccountsByMonth>.softwareAccount>.headerLogos {
    width: 100%;
    gap: 16px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.headerLogos>.row>div>h5 {
    line-height: 38.4px;
    font-size: 24px;
    text-align: right;
    font-weight: 400;
    color: #2B304F;
}

.headerLogos>.row>div>p {
    line-height: 19.2px;
    font-size: 12px;
    text-align: right;
    font-weight: 500;
    letter-spacing: -4%;
    color: #8B90A3;
    width: 80px;
}

.headerLogos>.row>img {
    margin-top: -15px;
    margin-right: -20px;
}

.infoAccount {
    padding: 0px 12px;
    font-size: 0px;
    line-height: 15.6px;
    font-weight: 400;
    color: #8B90A3;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    transition: flex-grow 0.5s ease, transform 1s ease, ;
    opacity: 0;
    transform: translateX(15%);
}

.softwareAccount>.normalWidth {
    flex-grow: 0;
}

.softwareAccount>.fullwidth {
    flex-grow: 1;
    transition: flex-grow 0.5s ease, transform 1s ease, ;
}

.infoAccount.show {
    flex-grow: 1;
    font-size: 12px;
    opacity: 1;
    transform: translateX(0);
    /* transition-delay: 0.25s; */
}
.infoAccount.show.fixedAI {
    max-width: 35%
}

.infoAccount.hidden {
    width: 0;
    opacity: 0;
    transition-delay: 0s;
    transform: translateX(10%)
}

.softwareAccount {
    flex-grow: 1;
    transition: flex-grow 1s ease, transform 1s ease ;
    transform: translateX(0);
}

.softwareAccount.fullwidth {
    height: 100%;
}

.softwareAccount.normalWidth {
    height: 100%;
}
.softwareAccount.fixedAI {
    width: 45%;
}

.softwareAccount>p {
    font-size: 12px;
    color: var(--text-light)
}

.infoAccount>div>p>strong.microsoft {
    color: #00dbff;
}

.infoAccount>div>p>strong.teams {
    color: #0772ab;
}

.infoAccount>div>p>strong.onedrive {
    color: #471fcc;
}

.infoAccount>div>p>strong.exchange {
    color: #28a8ea;
}

.infoAccount>div>p>strong.sharepoint {
    color: #34c38f;
}