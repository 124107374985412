
.slide-container {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.slide-item {
    animation: slideIn 0.5s ease-out;
    opacity: 1;
    transform: translateX(0);
}

.slide-item.sliding-out {
    animation: slideOut 0.5s ease-out;

}.slide-item.automation-content {
    display: flex;
    flex-direction: column;
    width: 100%;

}
.subtitle-dash.ellipsis.nextScheduleAutomation {
    color: #fff;
    display: flex;
    flex-direction: row;
    overflow: visible;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.automation-content {
    transition: all 0.3s ease-out;
}