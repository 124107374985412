.collectorsChart {
    max-height: 280px;
}

 


@media screen and (min-width: 1400px) {
        .collectorsChart {
        min-height: 450px;
    }
 
    
 
}

@media screen and (min-width: 1700px) {
        .collectorsChart {
        min-height: 540px;
    } 
 
}
@media screen and (max-height: 800px) {
    .collectorsChart {
        min-height: 280px;
    } 
 
}


.legend-title-home {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: var(--text-light);
    text-align: center;
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 16px */
    letter-spacing: -0.4px;
}

.EdrHome{
    min-height: 40% !important;
    width: 100%;
}
.XDRHomeWithLW {
    min-height: 30% !important;
    margin-bottom: 3px;
    width: 100%;
}
.divWorkStations {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0px;
    height:100%;
    gap:6px
}

.workstations {
    background-color: #f1f5fb;
    border-radius: 4px;
    display: flex;
    font-size: 1rem;
    padding:  10px;
    justify-content: space-between;
}

.workstations.colored {
    background: #2E195626;
    color: #fff;
}

.iconvaluecontent.workstations.colored > .truncate-container > .truncate-text.description {
    font-size: .8rem;
    margin-top:2px;
    color: #fff !important;
}

.iconvaluecontent.workstations.colored > .truncate-container > .truncate-text.data > div{
    font-size:1.2rem !important;
    color: #fff !important;

}
.legendWorkStations {
    font-size: .7rem;
    display: flex;
    align-items: center;
    padding: 5px 0px; 
    color: var(--text-light);
}
.legendWorkStations.colored {
    color: #fff;
}

.mostTargetsTable > .body > .lc-accordion > .header  > .title {
    font-size: 1rem;
}