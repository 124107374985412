.webix_control button,
.webix_control input,
.webix_control textarea,
.webix_el_label,
.webix_inp_bottom_label,
.webix_inp_label,
.webix_inp_top_label {
    font-family: "Ubuntu", sans-serif !important;
}

.webix_button,
.webixbutton {
    font-family: "Ubuntu", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
}

.webix_view,
.webix_hcell {
    font-family: "Ubuntu", sans-serif !important;
    color: var(--color-primary-purple-dark);
    font-size: 12px;
}

.webix_column>.webix_max_x {
    background-color: #ffc7c1;
    color: #000;
}

.analytical > .webix_ss_body > .webix_ss_center > .webix_ss_center_scroll > .webix_column>.webix_max_y {
    background-color: #e57373;
    color: #fff;
}
.webix_column>.webix_max_y {
    color: #000;
}

.webix_column>.webix_min_x {
    background-color: #d4f6e2;
    color: #000;
}

.webix_column>.webix_min_y {
    background-color: #81c784;
    color: #000;
}

.webix_hcell {
    font-weight: 600;
}

.webix_selected.webix_segment_N {
    background-color: #2E1956 !important
}

.webix_selected.webix_segment_0 {
    background-color: #2E1956 !important
}

.webix_selected.webix_segment_1 {
    background-color: #2E1956 !important
}

.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
    border-color: #F4F5F9;
    background-color: #F4F5F9;
    color: #2E1956;
    font-weight: 500;
}

.r-align {
    text-align: right;
}

.webix_custom_checkbox {
    color: #2E1956;
}

.webix_custom_radio {
    color: #2E1956;
}

.webix_primary .webix_button {
    background: #2E1956;
    color: #FFFFFF;
    border-radius: 4px;
}

.webix_primary .webix_button:active {
    background-color: #1e2557;
    opacity: 0.5;
}

.webix_primary .webix_button:focus {
    background-color: #1e2557;
    opacity: 0.5;
}

.webix_primary .webix_button:hover {
    background-color: #1e2557;
    opacity: 0.5;
}

.webix_pivot_settings {
    display: none !important;
}

.webix_el_segmented {
    display: none !important;
}

.webix_el_radio .webix_radio_1 .webix_custom_radio {
    color: #00AEE1;
}

.webix_checkbox_1 .webix_custom_checkbox {
    color: #00AEE1;
}

.webix_custom_checkbox:focus {
    color: #00AEE1;
}


.webix_secondary .webix_button {
    color: #00AEE1;
}

.webix_multilist .wxi-checkbox-marked {
    color: #00AEE1;
}

.webix_pivot_filter .webix_template .webix_pivot_active_filter,
.webix_pivot_filter .webix_template .webix_pivot_active_filter .webix_pivot_toolbar_icon {
    color: #00AEE1;
}

.webix_list_item .webix_pivot_list_marker_fill::before {
    background-color: #00AEE1;
}

.webix_list_item .webix_pivot_list_marker::before {
    border: 1px solid #00AEE1;
}

.webix_pivot_configuration .webix_accordionitem_label {
    color: #2E1956;
}

.webix_accordionitem_header {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    border-radius: 0.25rem;
    font-size: 14px;
    color: #2E1956;
}

.webix_pivot_add_value {
    color: #00AEE1;
}

.webix_dtable .webix_select_mark>div.webix_row_select {
    box-shadow: inset 2px 0 #00AEE1;
}

.webix_pivot_config_container {
    height: calc(100vh - 100px) !important;
    top: 81px;
    position: fixed;
    right: 0;
    min-width: 467px !important;
}

.webix_pivot_config_container>.webix_layout_line {
    height: calc(100vh - 100px) !important;
}

.webix_shadow_medium {
    box-shadow: -10px 0 18px 0 rgba(58, 79, 135, .06);
}


.webix_el_combo input,
.webix_el_richselect input,
.webix_el_datepicker input,
.webix_el_colorpicker input,
.webix_el_search input,
.webix_el_combo .webix_inp_static,
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static,
.webix_el_search .webix_inp_static {
    color: #2B304F;
    background: #ffffff;
    font-family: "Ubuntu", sans-serif;
    border-color: #ebebeb;
    border-radius: 0.25rem;
    cursor: pointer;
}


.webix_el_richselect .webix_inp_static:focus,
.webix_el_datepicker .webix_inp_static:focus,
.webix_el_colorpicker .webix_inp_static:focus {
    border: 1px solid #9196A7;
}


.webix_dtable .webix_ss_header .webix_hcolumn div.webix_last_row,
.webix_dtable .webix_ss_header .webix_span.webix_last_row {
    border-bottom: 1px solid #00AEE1;
}


.webix_window {
    box-shadow: 0px 0px 18px 0px rgba(58, 79, 135, 0.2);
    border: 1px solid #cccccf;
    border-radius: 0.25rem;
    margin-top: 6px;
}


.pivot-data .webix_list_item:hover {
    background-color: #ebebeb;
}


.pivot-data .webix_list_item.webix_selected {
    color: #2B304F;
    background: #ebebeb;
}


.wxi-menu-down:before {
    content: "\F001";
}


.portlet_drag {
    cursor: move;
}

.portlet_marker {
    background: #ffb800 !important;
}

.webix_el_search input,
.webix_el_text input,
.webix_el_combo input,
.webix_el_colorpicker input,
.webix_el_datepicker input {
    border-radius: 4px !important;
}

.webix_el_text input,
.webix_el_search input,
.webix_el_combo input,
.webix_el_datepicker input,
.webix_el_colorpicker input {
    border: 1px solid #ebebeb;
    color: #2B304F;
    background: #ffffff;
    font-family: "Ubuntu", sans-serif;
    border-color: #ebebeb;
    border-radius: 4px;
}

.webix_el_search input:focus,
.webix_el_text input:focus,
.webix_el_combo input:focus,
.webix_el_colorpicker input:focus,
.webix_el_datepicker input:focus {
    border: 1px solid #cccccf;
}


/*settings toggle*/
.webix_drop_area {
    width: 100%;
    height: 100%;
}

.field-filter {
    color: #dadee0;
    float: right;
    margin-top: 6px;
}

.field-filter-active {
    color: #00AEE1;
}

.field-name {
    line-height: 28px;
    font-weight: 600;
}

.field-name .field-name-sub {
    font-weight: 400;
}

.field-move {
    line-height: 16px;
}

.field-move .webix_template div {
    color: #94a1b3;
    font-size: 10px;
}

.dnd-list {
    border: 1px dashed #00AEE1;
    background: #fff;
}

.dnd-list .webix_list_item {
    background: white;
    line-height: 31px;
}

.webix_layout_space,
.webix_layout_wide {
    background-color: transparent;
}

.dnd-list .webix_list_item {
    height: 36px !important;
}


.webix_list-y .webix_list_item {
    border-bottom: 1px solid #edeff0;
}

.webix_drop_area {
    background-color: #ffb80036;
    height: 36px !important;
}

.pivot-toggle {
    display: none;
}

.webix_view.webix_scrollview.webix_custom_scroll {
    height: calc(100vh - 160px) !important;
    width: 100% !important;
}

.webix_ss_header span.webix_pivot_operation {
    display: none;
}

.webix_ss_footer .webix_last_row {
    text-align: right;
}

.webix_ss_footer .webix_first.webix_last_row {
    text-align: left;
}

/*nodos da árvore expandidos ficam em negrito*/
.webix_pivot:has(.webix_pivot_tree_header) .webix_cell[aria-expanded='true'] {
    font-weight: bold;
}

.same-level-bold {
    font-weight: bold;
}

/*se possui a propriedade [aria-expanded] possui nodos filhos*/
/* .webix_pivot:has(.webix_pivot_tree_header) .webix_cell[aria-expanded] {
    text-align: right;
} */

/*quando possuir nodos filhos e for primeira coluna, alinhar o texto a esquerda*/
.webix_pivot:has(.webix_pivot_tree_header) .webix_ss_center .webix_first .webix_cell[aria-expanded] {
    text-align: left;
}

.webix_view:not(.webix_pivot_tree_header) .toogle-tree .webix_el_box {
    display: none;
}

.webix_view:has(.webix_pivot_tree_header) .toogle-tree .webix_el_box {
    display: inline-block;
}

.webix_view:has(.webix_pivot_tree_header) .toogle-tree .webix_pressed .webix_button {
    box-shadow: none;
    background-color: transparent;
}

.webix_view:has(.webix_pivot_tree_header) .toogle-tree button {
    text-align: left;
    padding-left: 8px;
    background-color: transparent !important;
}

.lc-pivot {
    height: calc(95vh - 125px);
}

.webix_resizer_x {
    background-color: transparent;
}

/* 
.webix_ss_center {
    min-width: 100%;
} */